/*
 * Copyright 2024 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

.language-dropdown {
    max-width: 150px;
    max-height: 35px;
    margin: 5px 5px 5px auto;
    padding: 5px 5px;
    border: 1px var(--color-text-secondary) solid;
    position: absolute;
    top: 0;
    right: 0;
}

.p-dropdown-item {
  color: var(--color-primary);
}

  
/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
.public-page-hero-header {
  margin-top: -4.375rem;
  max-width: 37.5rem;
  color: var(--surface-a);
  line-height: var(--line-height);
  font-family: var(--font-family-montserrat);

  a {
    color: var(--surface-a);
  }

  .public-page-title {
    font-family: var(--font-family-montserrat);
    font-weight: var(--font-weight-bolder);
    font-size: 3.375rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
    margin-left: 0.625rem;
  }
  
  .public-page-pre-title {
    margin-bottom: 15px;
    font-size: 1.3rem;
    font-weight: var(--font-weight-medium);
    text-transform: uppercase;
    margin-left: 0.625rem;
  }
  
  .public-page-description {
    display: inline-block;
    padding-left: 5px;
  }
  
  .public-page-green-description {
    display: inline-block;
    background-color: #79B942;
    padding: 5px;
    font-weight: var(--font-weight-bold); 
    margin-left: 10px;
    }
}

.public-page-white-arrow {
  position: relative;
  top: -25px;
  left: 312px;
}

.public-page-image {
  padding-top: 5px;
  width: 520px;
  box-shadow: 0px 14px 24px rgba(0,0,0, 0.25);
}

@media screen and (max-width: 1060px){
  .public-page-hero-header{
    width: 85%;
  }

  .public-page-image {
    max-width: 350px;
    margin: auto;
  }
}
